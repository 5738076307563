import { render, staticRenderFns } from "./QuestionCategoryDetail.vue?vue&type=template&id=1f2d1787&scoped=true&"
import script from "./QuestionCategoryDetail.vue?vue&type=script&lang=js&"
export * from "./QuestionCategoryDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2d1787",
  null
  
)

export default component.exports